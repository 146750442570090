<template>
  <div>
    <Movementmenu />
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลการย้าย สายการสอนและสายสนับสนุน (ประมวลผลแบบที่ 1)"
      >
        <v-row no-gutters>
          <!--         <v-col cols="12" md="2" class="pa-1">
              <v-select
                v-model="times_select"
                :items="time_ss"
                item-value="time_ss"
                :value="1"
                label="ครั้งที่ :"
              ></v-select>
            </v-col>
            <v-col cols="12" md="2" class="pa-1">
              <v-select
                v-model="years_select"
                :items="year_ss"
                item-value="year_ss"
                :value="2565"
                label="ปีที่ :"
              ></v-select>
            </v-col> -->

          <v-col cols="12" md="6" class="pa-1">
            <!--  <v-btn
                rounded
                color="info"
                :href="
                  '#/admin/print_assess1420/' +
                    periods.period_times +
                    '/' +
                    period_years
                "
                target="_blank"
                ><v-icon>mdi-printer</v-icon>แบบประเมินคะแนน 1.4 (1)
              </v-btn>

              <v-btn
                rounded
                color="info"
                :href="
                  '#/admin/print_assess1420detail/' +
                    periods.period_times +
                    '/' +
                    period_years
                "
                target="_blank"
                ><v-icon>mdi-printer</v-icon>แบบประเมินคะแนน 1.4 (2)
              </v-btn> -->
          </v-col>
          <v-col cols="12" md="12">
            <v-card class="pa-1">
              <v-simple-table>
                <tbody>
                  <tr>
                    <td class="text-center">
                      <v-chip
                        color="info"
                        @click="transference_personnelQueryAll()"
                      >
                        <v-icon>mdi-account-multiple</v-icon>
                        จำนวนผู้เสนอย้าย :
                        {{ transference_personnelCount.sumAll }} คน</v-chip
                      >
                    </td>
                    <td class="text-center">
                      แนบไฟล์เสนอย้าย :
                      {{ transference_personnelCount.sumdoc }} คน
                    </td>
                    <td class="text-center">
                      แนบไฟล์ สอจ. :
                      {{ transference_personnelCount.sumdocpvcfile }} คน
                    </td>
                    <td class="text-center">
                      <v-btn
                        rounded
                        color="success"
                        @click="showSuccessPosition()"
                        ><v-icon>mdi-account-search</v-icon>
                        รายการที่สำเร็จแล้ว
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <v-chip color="warning" dark @click="SearchCCdoc()">
                        <v-icon>mdi-alert</v-icon>
                        วิทยาลัยขอยกเลิกแนบไฟล์เสนอย้าย :
                        {{ transference_personnelCount.sumcc }} ราย
                      </v-chip>
                    </td>
                    <td class="text-center">
                      <v-chip color="warning" dark @click="SearchCCpvcdoc()">
                        <v-icon>mdi-alert</v-icon> สอจ. ขอยกเลิกแนบไฟล์คะแนน :
                        {{ transference_personnelCount.sumpvccc }} ราย
                      </v-chip>
                    </td>

                    <td class="text-center">
                      <v-btn color="warning" rounded @click="Searchtemporary()"
                        >ไม่ได้บันทึกรายการ</v-btn
                      >
                    </td>

                    <td class="text-center">
                      <v-btn color="warning" rounded @click="optimizeData()">
                        <v-icon>mdi-database</v-icon>
                        จัดระเบียบข้อมูล</v-btn
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      เงือนไข 4 ปี :
                      {{ transference_personnelCount.conditions }} คน
                    </td>
                    <td class="text-center">
                      ครูคืนถื่น 5 ปี :
                      {{ transference_personnelCount.localDevs }} คน
                    </td>
                    <td class="text-center">
                      จชต. : {{ transference_personnelCount.sounths }} คน
                    </td>
                    <td class="text-center">
                      <v-btn @click="clearRecordDontSave()" rounded color="red">
                        <v-icon>mdi-delete-forever</v-icon>
                        ลบรายการที่ไม่ได้บันทึก</v-btn
                      >
                    </td>
                  </tr>
                </tbody>
              </v-simple-table></v-card
            >
          </v-col>
        </v-row>

        <v-select
          v-model="selectedHeaders"
          :items="headers"
          label="เลือกคอลัมน์ที่ต้องการแสดง"
          multiple
          outlined
          return-object
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 6">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 6" class="grey--text caption"
              >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
            >
          </template>
        </v-select>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
          single-line
          hide-details
          dense
          filled
          class="mb-2"
        />

        <v-data-table
          color="success"
          :loading="loading"
          :headers="showHeaders"
          :items="transference_personnels"
          :search="search"
          item-key="tid_ref"
          :item-class="row_classes"
        >
          <template v-slot:item.updateFileDoc="{ item }">
            <v-chip color="success" v-if="item.documentsLinkfile">
              แนบไฟล์เรียบร้อย
            </v-chip>
            <v-chip @click="updateFileDoc(item.tid_ref)" color="warning" v-else>
              ยังไม่ได้แนบไฟล์
            </v-chip>
          </template>

          <template v-slot:item.transfer_status="{ item }">
            <v-chip color="success" v-if="item.transfer_status === 'save'">
              บันทึก
            </v-chip>
            <v-chip @click="changeStatus(item.tid_ref)" color="warning" v-else>
              ยังไม่ได้บันทึก
            </v-chip>
          </template>

          <template v-slot:item.college_name="{ item }">
            <v-chip color="">
              <span style="font-size:16px;"> {{ item.college_name }}</span>
            </v-chip>
          </template>

          <template v-slot:item.time_ss="{ item }">
            {{ item.time_ss + "/" + item.year_ss }}
          </template>

          <template v-slot:item.succ_college="{ item }">
            <v-chip :color="getColor(item.succ_college)" dark>
              <span style="font-size:16px;"> {{ item.succ_college }}</span>
            </v-chip>
          </template>

          <template v-slot:item.college_name_suss="{ item }">
            <v-chip v-if="item.college_name_suss === ''"> </v-chip>
            <v-chip
              v-else-if="item.college_name_suss !== ''"
              color="green"
              dark
            >
              <span style="font-size:16px;"> {{ item.college_name_suss }}</span>
            </v-chip>
          </template>

          <template v-slot:item.comment_dr_c="{ item }">
            <v-chip
              class="ma-1"
              v-if="item.comment_dr_c === 'approp'"
              color="green"
              @click.stop="comment_idPosition(item.tid_ref)"
              dark
              >เห็นควร</v-chip
            >
            <v-chip
              v-else-if="item.comment_dr_c === 'inapprop'"
              color="warning"
              dark
              @click.stop="comment_idPosition(item.tid_ref)"
              >ไม่เห็นควร</v-chip
            >

            <v-chip
              v-else-if="item.comment_dr_c === 'suspend'"
              color="red"
              dark
              @click.stop="comment_idPosition(item.tid_ref)"
              >ระงับย้าย</v-chip
            >

            <v-icon
              large
              v-else
              color="info"
              @click.stop="comment_idPosition(item.tid_ref)"
              >mdi-comment-processing</v-icon
            >
          </template>

          <template v-slot:item.tp_comment_dr_stb="{ item }">
            <v-chip
              class="ma-1"
              v-if="item.tp_comment_dr_stb === 'approp'"
              color="green"
              @click.stop="tp_comment_dr_stbPosition(item.tid_ref)"
              dark
              >เห็นควร</v-chip
            >
            <v-chip
              v-else-if="item.tp_comment_dr_stb === 'inapprop'"
              color="warning"
              dark
              @click.stop="tp_comment_dr_stbPosition(item.tid_ref)"
              >ไม่เห็นควร</v-chip
            >

            <v-chip
              v-else-if="item.tp_comment_dr_stb === 'suspend'"
              color="red"
              dark
              @click.stop="tp_comment_dr_stbPosition(item.tid_ref)"
              >ระงับย้าย</v-chip
            >

            <v-icon
              large
              v-else
              color="info"
              @click.stop="tp_comment_dr_stbPosition(item.tid_ref)"
              >mdi-comment-processing</v-icon
            >
          </template>

          <template v-slot:item.status_document="{ item }">
            <v-chip
              v-if="item.status_document === 'complete'"
              color="green"
              @click.stop="comment_idPosition(item.tid_ref)"
              dark
              >ถูกต้องสมบูรณ์</v-chip
            >
            <v-chip
              v-else-if="item.status_document === 'incomplete'"
              color="warning"
              dark
              @click.stop="comment_idPosition(item.tid_ref)"
              >ไม่สมบูรณ์</v-chip
            >
            <v-chip
              v-else-if="item.status_document === 'do_not'"
              color="red"
              dark
              @click.stop="comment_idPosition(item.tid_ref)"
              >ไม่จัดส่ง</v-chip
            >
          </template>

          <template v-slot:item.actions="{ item }">
            <v-chip v-if="item.status_select === 'agree'" color="warning" dark>
              <span style="font-size:16px;">ปกติ (2)</span>
            </v-chip>
            <v-chip
              v-else-if="item.status_select === 'sw_normal'"
              color="warning"
              dark
            >
              <span style="font-size:16px;">สับเปลี่ยน (3)</span>
            </v-chip>
            <v-chip
              v-else-if="item.status_select === 'sw_agree'"
              color="warning"
              dark
            >
              <span style="font-size:16px;">แลกเปลี่ยน (4)</span>
            </v-chip>
            <v-icon
              v-else-if="item.succ_college >= 1"
              color="red"
              large
              @click.stop="deletePosition(item.id_ref)"
              >mdi-delete-circle</v-icon
            >
            <v-icon
              v-else
              color="info"
              large
              @click.stop="select_idPosition(item.tid_ref)"
              >mdi-credit-card-plus</v-icon
            >
          </template>

          <template v-slot:item.MoveCut="{ item }">
            <v-chip v-if="item.status_select === 'agree'" color="warning" dark>
              <span style="font-size:16px;">ปกติ (2)</span>
            </v-chip>
            <v-chip
              v-else-if="item.status_select === 'transfer'"
              color="warning"
              dark
            >
              <span style="font-size:16px;">ตัดโอน</span>
            </v-chip>

            <v-chip
              v-else-if="item.status_select === 'sw_normal'"
              color="warning"
              dark
            >
              <span style="font-size:16px;">สับเปลี่ยน (3)</span>
            </v-chip>

            <v-chip
              v-else-if="item.status_select === 'sw_agree'"
              color="warning"
              dark
            >
              <span style="font-size:16px;">แลกเปลี่ยน (4)</span>
            </v-chip>
            <v-icon
              v-else-if="item.succ_college >= 1"
              color="red"
              large
              @click.stop="deletePosition(item.id_ref)"
              >mdi-delete-circle</v-icon
            >
            <v-icon
              v-else
              color="info"
              large
              @click.stop="select_idPositionMoveCut(item.tid_ref)"
              >mdi-paper-cut-vertical</v-icon
            >
          </template>

          <template v-slot:item.documentsLinkfile="{ item }">
            <div v-if="item.documentsLinkfile">
              <div>
                <v-icon color="red" large v-if="item.ccDocumentFile === 'cc'"
                  >mdi-information</v-icon
                >
                <v-btn
                  large
                  text
                  rounded
                  color="green"
                  @click="viewdocumentsLinkfile(item.documentsLinkfile)"
                  ><v-icon large>mdi-printer</v-icon></v-btn
                >
                <v-icon
                  large
                  color="red"
                  @click.stop="CancelFilePDF(item.tid_ref)"
                  >mdi-delete-circle</v-icon
                >
              </div>
            </div>
            <div v-else>
              <v-chip dark color="warning">
                ไม่ได้แนบไฟล์
              </v-chip>
            </div>
          </template>

          <!--    <template v-slot:item.doccon_1="{ item }">
            <div v-if="item.doccon_1">
              <v-icon color="red" large v-if="item.ccDocumentFile==='cc'">mdi-information</v-icon>
              <v-btn
                text
                rounded
                color="green"
                large
                :href="'/HRvecfiles/' + item.doccon_1"
                target="_blank"
                ><v-icon large>mdi-printer</v-icon></v-btn
              >
              <v-icon
                color="red"
                large
                @click.stop="CancelFilePDF(item.tid_ref)"
                >mdi-delete-circle</v-icon
              >
            </div>
            <div v-else>
              <v-chip dark color="warning">
                ไม่ได้แนบไฟล์
              </v-chip>
            </div>
          </template> -->

          <template v-slot:item.doccon_14="{ item }">
            <div v-if="item.doccon_14">
              <v-icon color="red" large v-if="item.ccDocumentFile === 'cc'"
                >mdi-information</v-icon
              >
              <v-btn
                text
                rounded
                color="green"
                large
                :href="'/HRvecfiles/' + item.doccon_14"
                target="_blank"
                ><v-icon large>mdi-printer</v-icon></v-btn
              >
              <v-icon
                color="red"
                large
                @click.stop="CancelFilePDF(item.tid_ref)"
                >mdi-delete-circle</v-icon
              >
            </div>
            <div v-else>
              <v-chip dark color="warning">
                ไม่ได้แนบไฟล์
              </v-chip>
            </div>
          </template>

          <template v-slot:item.tpvecprovince_scoreFile="{ item }">
            <div v-if="item.tpvecprovince_scoreFile">
              <v-icon color="red" large v-if="item.ccDocumentFilePVC === 'cc'"
                >mdi-information</v-icon
              >
              <v-btn
                text
                rounded
                color="green"
                large
                :href="'/HRvecfiles/' + item.tpvecprovince_scoreFile"
                target="_blank"
                ><v-icon large>mdi-printer</v-icon></v-btn
              >
              <v-icon
                color="red"
                large
                @click.stop="CancelFilePDFProvince(item.tid_ref)"
                >mdi-delete-circle</v-icon
              >
            </div>
            <div v-else>
              <v-chip dark color="warning">
                ไม่ได้แนบไฟล์
              </v-chip>
            </div>
          </template>

          <template v-slot:item.score_1420="{ item }">
            <v-text-field
              v-model="item.score_1420"
              @change="updateScore1420(item.id_tfp, item.score_1420)"
            >
            </v-text-field>
          </template>

          <template v-slot:item.actionReceipt="{ item }">
            <v-btn
              v-if="item.tp14receipt === 'success'"
              @click="actionReceiptUdate(item.id_tfp)"
              rounded
              text
              color="green"
              large
              ><v-icon>mdi-checkbox-marked-circle</v-icon></v-btn
            >
            <v-btn
              v-else
              @click="actionReceipts(item.id_tfp)"
              rounded
              text
              color="grey"
              large
              ><v-icon>mdi-dots-horizontal-circle</v-icon></v-btn
            >
          </template>

          <template v-slot:item.dateApps="{ item }">
            <div>
              {{ item.dateApps | moment("D MMMM YYYY") }}
            </div>
          </template>

          <template v-slot:item.type_personnel="{ item }">
            <div>
              <span v-if="item.type_personnel === 'select'">รอบคัดเลือก</span>
              <span v-else-if="item.type_personnel === 'local_dev'"
                >ครูพัฒนาท้องถิ่น</span
              >
              <span v-else-if="item.type_personnel === 'recruit'">แข่งขัน</span>
            </div>
          </template>

          <template v-slot:item.del="{ item }">
            <v-icon color="red" large @click.stop="deletetpteach(item.tid_ref)"
              >mdi-delete-circle</v-icon
            >
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model canceldialog -->
      <v-layout row justify-center>
        <v-dialog v-model="canceldialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="ยกเลิกรายการ"
              class="px-5 py-3 text_google"
            >
              <div class="text-right"></div>
            </base-material-card>
            <v-card-text>
              <v-form ref="cancelform" lazy-validation>
                {{ conditons_transfer_successs.id_cts }}
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      <p>
                        รหัสอ้างอิง :
                        {{ transference_personnels_id_ref.id_ref }}
                      </p>

                      <div class="text--center">
                        รหัสบัตรประชาชน :
                        {{ transference_personnels_id_ref.id_card }}
                        ชื่อ-นามสกุล :
                        {{ transference_personnels_id_ref.title_s
                        }}{{ transference_personnels_id_ref.frist_name }}
                        {{ transference_personnels_id_ref.last_name }}

                        สถานศึกษาปัจจุบัน :
                        {{ transference_personnels_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_personnels_id_ref.id_position }}
                      </div>
                    </v-flex>
                    <v-flex md6>
                      <p>
                        รหัสวิทยาลัยปลายทาง :
                        {{ man_powerss.college_code || "สับเปลี่ยนตำแหน่ง" }}
                      </p>
                      <div>
                        วิทยาลัย :
                        {{ man_powerss.college_name || "สับเปลี่ยนตำแหน่ง" }}
                      </div>
                      เลขที่ตำแหน่ง :
                      {{ man_powerss.id_position || "สับเปลี่ยนตำแหน่ง" }}
                    </v-flex>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="canceldialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn large color="warning" @click.stop="cancelSubmit()" rounded>
                <v-icon dark>mdi-pencil</v-icon>&nbsp;ยืนยัน
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model positiondialog -->
      <v-layout row justify-center>
        <v-dialog v-model="positiondialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="เลือกตำแหน่ง"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="updatepositionform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ transference_personnels_id_ref.tid_ref }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ transference_personnels_id_ref.id_card }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ transference_personnels_id_ref.title_s
                        }}{{ transference_personnels_id_ref.frist_name }}
                        {{ transference_personnels_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ transference_personnels_id_ref.college_code }}
                        {{ transference_personnels_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_personnels_id_ref.id_position }}
                      </h3>
                      <h4>
                        ย้ายครั้งที่ :
                        {{ transference_personnels_id_ref.time_ss }} ปี :
                        {{ this.transference_personnels_id_ref.year_ss }}
                      </h4>
                      <h4>
                        คำนวณอายุงาน ณ สถานศึกษาปัจจุบัน โดยใช้วันที่ :
                        {{
                          periods.period_cal_end
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h4>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-data-table
                        color="success"
                        :loading="loading"
                        :headers="header_trans"
                        :items="transference_locations"
                        class="elevation-1"
                      >
                        <template v-slot:item.tlMcommittee="{ item }">
                          <div class="text-center">
                            <v-chip
                              v-if="item.tlMcommittee === 'approp'"
                              color="green"
                              dark
                              @click="canceltlMcommittee(item.id_tfl)"
                            >
                              <v-icon>mdi-printer</v-icon>
                              เห็นควร
                            </v-chip>
                            <v-chip
                              v-else-if="item.tlMcommittee === 'inapprop'"
                              color="warning"
                              dark
                              :href="'/HRvecfiles/' + item.tlMdocumentsLinkfile"
                              target="_blank"
                              ><v-icon>mdi-printer</v-icon>
                              ไม่เห็นควร
                            </v-chip>
                            <v-icon v-else color="error" large
                              >mdi-information</v-icon
                            >
                          </div>
                        </template>

                        <template v-slot:item.tlMdocumentsLinkfile="{ item }">
                          <div
                            class="text-center"
                            v-if="item.tlMdocumentsLinkfile"
                          >
                            <v-btn
                              text
                              rounded
                              color="green"
                              large
                              :href="'/HRvecfiles/' + item.tlMdocumentsLinkfile"
                              target="_blank"
                              ><v-icon>mdi-printer</v-icon></v-btn
                            >
                          </div>
                          <div v-else>
                            <v-chip dark color="warning">
                              ไม่ได้แนบไฟล์
                            </v-chip>
                          </div>
                        </template>

                        <template v-slot:item.idPosition="{ item }">
                          <v-btn
                            @click="
                              selectIdpostion(item.id_tfl, item.tlcollege_code)
                            "
                            text
                            rounded
                            color="info"
                            large
                            ><v-icon>mdi-checkbox-marked-circle</v-icon></v-btn
                          >
                        </template>

                        <template v-slot:item.actions="{ item }">
                          <v-btn
                            @click="canceltlMcommittee(item.id_tfl)"
                            text
                            rounded
                            color="red"
                            large
                            ><v-icon>mdi-delete-circle</v-icon></v-btn
                          >
                        </template>
                      </v-data-table>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-card elevation="2" class="pa-2">
                        <h2>
                          เหตุผลในการย้าย :
                        </h2>

                        <div
                          v-if="transference_personnels_id_ref.reason_1 === '1'"
                        >
                          <v-alert
                            border="left"
                            colored-border
                            type="info"
                            elevation="2"
                          >
                            <h3>ย้ายเพื่ออยู่ร่วมคู่สมรส</h3>
                            <h4>
                              คู่สมรสชื่อ :
                              {{
                                transference_personnels_id_ref.reason_1_spouse ||
                                  "-"
                              }}
                              คู่สมรสประกอบอาชีพ :
                              {{
                                transference_personnels_id_ref.reason_1_occupation ||
                                  "-"
                              }}
                              สถานที่ประกอบอาชีพของคู่สมรส :
                              {{
                                transference_personnels_id_ref.reason_1_location ||
                                  "-"
                              }}
                              ภูมิลำเนาของคู่สมรส จังหวัด
                              {{ transference_personnels_id_ref.r1_province }}
                            </h4>
                          </v-alert>
                        </div>

                        <div
                          v-if="transference_personnels_id_ref.reason_2 === '1'"
                        >
                          <v-alert
                            border="left"
                            colored-border
                            type="info"
                            elevation="2"
                          >
                            <h3>ย้ายเพื่ออยู่ดูแลบิดา มารดา</h3>
                            <h4>
                              อายุของบิดา :
                              {{
                                transference_personnels_id_ref.reason_2_fyear ||
                                  "-"
                              }}
                              ปี อายุของมารดา :
                              {{
                                transference_personnels_id_ref.reason_2_myear ||
                                  "-"
                              }}
                              ปี ภูมิลำเนาของบิดา มารดา จังหวัด :
                              {{ transference_personnels_id_ref.r2_province }}
                            </h4>
                          </v-alert>
                        </div>
                        <div
                          v-if="transference_personnels_id_ref.reason_3 === '1'"
                        >
                          <v-alert
                            border="left"
                            colored-border
                            type="info"
                            elevation="2"
                          >
                            <h3>ย้ายกลับภูมิลำเนา</h3>

                            <h4>
                              จังหวัด :
                              {{ transference_personnels_id_ref.r3_province }}
                            </h4>
                          </v-alert>
                        </div>
                        <div
                          v-if="transference_personnels_id_ref.reason_4 === '1'"
                        >
                          <v-alert
                            border="left"
                            colored-border
                            type="info"
                            elevation="2"
                          >
                            <h3>
                              เหตุผลอื่น ๆ :
                            </h3>
                            <h4>
                              {{
                                transference_personnels_id_ref.reason_4_detail
                              }}
                            </h4>
                          </v-alert>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-col cols="12" md="12">
                    <v-card elevation="2" class="pa-2">
                      <v-flex md12>
                        <v-autocomplete
                          :items="branch_s"
                          item-text="name_branch"
                          item-value="id_branch"
                          outlined
                          label="สาขาวิชา :"
                          prepend-icon="mdi-account-details"
                          request
                          v-model="updatepositions.ctsid_branch"
                          :rules="[v => !!v || '']"
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex xs12>
                        <v-autocomplete
                          prepend-icon="mdi-account-details"
                          :items="colleges"
                          item-text="college_name"
                          item-value="college_code"
                          v-model="updatepositions.ctscollege_code"
                          label="วิทยาลัย"
                          @change="man_powerQuery()"
                          required
                          :rules="[v => !!v || '']"
                          outlined
                        >
                        </v-autocomplete>
                      </v-flex>
                      <v-flex md12>
                        <v-autocomplete
                          prepend-icon="mdi-account-details"
                          :items="man_powers"
                          item-text="college_position_case"
                          item-value="id_position"
                          label="เลขที่ตำแหน่ง"
                          v-model="updatepositions.ctsid_position"
                          outlined
                          required
                          :rules="[v => !!v || '']"
                        ></v-autocomplete>
                      </v-flex>
                    </v-card>
                  </v-col>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="positiondialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="updatepositionSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model positionMoveCutdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="positionMoveCutdialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="ตัดโอน"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="updatepositionMoveCutform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ transference_personnels_id_ref.tid_ref }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ transference_personnels_id_ref.id_card }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ transference_personnels_id_ref.title_s
                        }}{{ transference_personnels_id_ref.frist_name }}
                        {{ transference_personnels_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ transference_personnels_id_ref.college_code }}
                        {{ transference_personnels_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_personnels_id_ref.id_position }}
                      </h3>
                      <h4>
                        ย้ายครั้งที่ :
                        {{ transference_personnels_id_ref.time_ss }} ปี :
                        {{ this.transference_personnels_id_ref.year_ss }}
                      </h4>
                      <h4>
                        คำนวณอายุงาน ณ สถานศึกษาปัจจุบัน โดยใช้วันที่ :
                        {{
                          periods.period_cal_end
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h4>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <v-data-table
                        color="success"
                        :loading="loading"
                        :headers="header_trans"
                        :items="transference_locations"
                        class="elevation-1"
                      >
                        <template v-slot:item.tlMcommittee="{ item }">
                          <div class="text-center">
                            <v-chip
                              v-if="item.tlMcommittee === 'approp'"
                              color="green"
                              dark
                              @click="canceltlMcommittee(item.id_tfl)"
                            >
                              <v-icon>mdi-printer</v-icon>
                              เห็นควร
                            </v-chip>
                            <v-chip
                              v-else-if="item.tlMcommittee === 'inapprop'"
                              color="warning"
                              dark
                              :href="'/HRvecfiles/' + item.tlMdocumentsLinkfile"
                              target="_blank"
                              ><v-icon>mdi-printer</v-icon>
                              ไม่เห็นควร
                            </v-chip>
                            <v-icon v-else color="error" large
                              >mdi-information</v-icon
                            >
                          </div>
                        </template>

                        <template v-slot:item.tlMdocumentsLinkfile="{ item }">
                          <div
                            class="text-center"
                            v-if="item.tlMdocumentsLinkfile"
                          >
                            <v-btn
                              text
                              rounded
                              color="green"
                              large
                              :href="'/HRvecfiles/' + item.tlMdocumentsLinkfile"
                              target="_blank"
                              ><v-icon>mdi-printer</v-icon></v-btn
                            >
                          </div>
                          <div v-else>
                            <v-chip dark color="warning">
                              ไม่ได้แนบไฟล์
                            </v-chip>
                          </div>
                        </template>

                        <template v-slot:item.actions="{ item }">
                          <v-btn
                            @click="canceltlMcommittee(item.id_tfl)"
                            text
                            rounded
                            color="red"
                            large
                            ><v-icon>mdi-delete-circle</v-icon></v-btn
                          >
                        </template>
                      </v-data-table>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-card elevation="2" class="pa-2">
                        <h2>
                          เหตุผลในการย้าย :
                        </h2>

                        <div
                          v-if="transference_personnels_id_ref.reason_1 === '1'"
                        >
                          <v-alert
                            border="left"
                            colored-border
                            type="info"
                            elevation="2"
                          >
                            <h3>ย้ายเพื่ออยู่ร่วมคู่สมรส</h3>
                            <h4>
                              คู่สมรสชื่อ :
                              {{
                                transference_personnels_id_ref.reason_1_spouse ||
                                  "-"
                              }}
                              คู่สมรสประกอบอาชีพ :
                              {{
                                transference_personnels_id_ref.reason_1_occupation ||
                                  "-"
                              }}
                              สถานที่ประกอบอาชีพของคู่สมรส :
                              {{
                                transference_personnels_id_ref.reason_1_location ||
                                  "-"
                              }}
                              ภูมิลำเนาของคู่สมรส จังหวัด
                              {{ transference_personnels_id_ref.r1_province }}
                            </h4>
                          </v-alert>
                        </div>

                        <div
                          v-if="transference_personnels_id_ref.reason_2 === '1'"
                        >
                          <v-alert
                            border="left"
                            colored-border
                            type="info"
                            elevation="2"
                          >
                            <h3>ย้ายเพื่ออยู่ดูแลบิดา มารดา</h3>
                            <h4>
                              อายุของบิดา :
                              {{
                                transference_personnels_id_ref.reason_2_fyear ||
                                  "-"
                              }}
                              ปี อายุของมารดา :
                              {{
                                transference_personnels_id_ref.reason_2_myear ||
                                  "-"
                              }}
                              ปี ภูมิลำเนาของบิดา มารดา จังหวัด :
                              {{ transference_personnels_id_ref.r2_province }}
                            </h4>
                          </v-alert>
                        </div>
                        <div
                          v-if="transference_personnels_id_ref.reason_3 === '1'"
                        >
                          <v-alert
                            border="left"
                            colored-border
                            type="info"
                            elevation="2"
                          >
                            <h3>ย้ายกลับภูมิลำเนา</h3>

                            <h4>
                              จังหวัด :
                              {{ transference_personnels_id_ref.r3_province }}
                            </h4>
                          </v-alert>
                        </div>
                        <div
                          v-if="transference_personnels_id_ref.reason_4 === '1'"
                        >
                          <v-alert
                            border="left"
                            colored-border
                            type="info"
                            elevation="2"
                          >
                            <h3>
                              เหตุผลอื่น ๆ :
                            </h3>
                            <h4>
                              {{
                                transference_personnels_id_ref.reason_4_detail
                              }}
                            </h4>
                          </v-alert>
                        </div>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-col cols="12" md="12">
                    <v-card elevation="2" class="pa-2">
                      <v-flex md12>
                        <v-autocomplete
                          :items="branch_s"
                          item-text="name_branch"
                          item-value="id_branch"
                          outlined
                          label="สาขาวิชา :"
                          prepend-icon="mdi-account-details"
                          request
                          v-model="updatepositions.ctsid_branch"
                          :rules="[v => !!v || '']"
                        ></v-autocomplete>
                      </v-flex>

                      <v-flex xs12>
                        <v-autocomplete
                          prepend-icon="mdi-account-details"
                          :items="colleges"
                          item-text="college_name"
                          item-value="college_code"
                          v-model="updatepositions.ctscollege_code"
                          label="วิทยาลัย"
                          required
                          :rules="[v => !!v || '']"
                          outlined
                        >
                        </v-autocomplete>
                      </v-flex>
                    </v-card>
                  </v-col>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="positionMoveCutdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="updatepositionMoveCutSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model selectIdPositon -->
      <v-layout row justify-center>
        <v-dialog v-model="selectIdPositon" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="เลือกปลายทาง"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="updatepositionform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ transference_locationTfl.id_tfl }}
                        {{ transference_locationTfl.tlid_ref }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ transference_locationTfl.tlid_card }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ transference_locationTfl.title_s
                        }}{{ transference_locationTfl.frist_name }}
                        {{ transference_locationTfl.last_name }}
                        <br />

                        สถานศึกษาปลายทาง :
                        {{ transference_locationTfl.college_code }}
                        {{ transference_locationTfl.college_name }}
                        <br />
                        เลขที่ตำแหน่ง :

                        <v-autocomplete
                          prepend-icon="mdi-account-details"
                          :items="man_powers"
                          item-text="college_position_case"
                          item-value="id_position"
                          label="เลือก เลขที่ตำแหน่งว่าง"
                          v-model="updatepositions.ctsid_position"
                          outlined
                          required
                          :rules="[v => !!v || '']"
                        ></v-autocomplete>
                      </h3>

                      <h4>
                        สาขาวิชา
                        {{
                          transference_locationTfl.tlid_branch +
                            " : " +
                            transference_locationTfl.name_branch
                        }}
                      </h4>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="selectIdPositon = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="updatepositionSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model commentDrdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="commentDrdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="เพิ่มความคิดเห็นผู้อำนวยการ"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="updatecommentform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ transference_personnels_id_ref.tid_ref }}
                        {{ transference_personnels_id_ref.id_tfp }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ transference_personnels_id_ref.id_card }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ transference_personnels_id_ref.title_s
                        }}{{ transference_personnels_id_ref.frist_name }}
                        {{ transference_personnels_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ transference_personnels_id_ref.college_code }}
                        {{ transference_personnels_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_personnels_id_ref.id_position }}
                      </h3>
                      <h4>
                        ย้ายครั้งที่ :
                        {{ transference_personnels_id_ref.time_ss }} ปี :
                        {{ this.transference_personnels_id_ref.year_ss }}
                      </h4>
                      <h4>
                        คำนวณอายุงาน ณ สถานศึกษาปัจจุบัน โดยใช้วันที่ :
                        {{
                          periods.period_cal_end
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h4>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-alert
                        class="mx-auto text-center pa-2 ma-2"
                        border="top"
                        colored-border
                        type="info"
                        elevation="2"
                      >
                        <h2>ความคิดเห็น</h2>
                        <h2>
                          <v-radio-group
                            v-model="
                              transference_personnels_id_ref.comment_dr_c
                            "
                            row
                            required
                            :rules="[v => !!v || '']"
                            align="center"
                          >
                            <v-radio value="approp">
                              <template v-slot:label>
                                <div>
                                  <strong class="primary--text"
                                    >เห็นควรให้ย้าย</strong
                                  >
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="inapprop">
                              <template v-slot:label>
                                <div>
                                  <strong class="warning--text"
                                    >ไม่เห็นควรให้ย้าย</strong
                                  >
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="suspend">
                              <template v-slot:label>
                                <div>
                                  <strong class="red--text">ระงับย้าย</strong>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </h2>
                      </v-alert>
                    </v-col>

                    <v-col cols="12" md="12">
                      <v-alert
                        class="mx-auto text-center pa-2 ma-2"
                        border="top"
                        colored-border
                        type="info"
                        elevation="2"
                      >
                        <h2>สถานะการยืนเสนอเอกสารประกอบการพิจารณา</h2>
                        <h2>
                          <v-radio-group
                            v-model="
                              transference_personnels_id_ref.status_document
                            "
                            row
                            required
                            :rules="[v => !!v || '']"
                            align="center"
                          >
                            <v-radio value="complete">
                              <template v-slot:label>
                                <div>
                                  <strong class="primary--text"
                                    >ถูกต้องสมบูรณ์</strong
                                  >
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="incomplete">
                              <template v-slot:label>
                                <div>
                                  <strong class="warning--text"
                                    >ไม่ถูกต้องไม่สมบูรณ์</strong
                                  >
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="do_not">
                              <template v-slot:label>
                                <div>
                                  <strong class="red--text"
                                    >ไม่จัดส่งเอกสาร</strong
                                  >
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </h2>
                      </v-alert>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-autocomplete
                        :items="branch_s"
                        item-text="name_branch"
                        item-value="id_branch"
                        outlined
                        label="สาขาวิชารับย้าย :"
                        v-model="transference_personnels_id_ref.id_branch_tan"
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        outlined
                        label=" ไม่เห็นควรให้ย้ายเนื่องจาก:"
                        v-model="transference_personnels_id_ref.detail_comment"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="commentDrdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="updatecommentSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model updateFileDocdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="updateFileDocdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แนบไฟล์เอกสารประกอบการพิจารณา"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="updateFileDocform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ transference_personnels_id_ref.tid_ref }}
                        {{ transference_personnels_id_ref.id_tfp }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ transference_personnels_id_ref.id_card }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ transference_personnels_id_ref.title_s
                        }}{{ transference_personnels_id_ref.frist_name }}
                        {{ transference_personnels_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ transference_personnels_id_ref.college_code }}
                        {{ transference_personnels_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_personnels_id_ref.id_position }}
                      </h3>
                      <h4>
                        ย้ายครั้งที่ :
                        {{ transference_personnels_id_ref.time_ss }} ปี :
                        {{ this.transference_personnels_id_ref.year_ss }}
                      </h4>
                      <h4>
                        คำนวณอายุงาน ณ สถานศึกษาปัจจุบัน โดยใช้วันที่ :
                        {{
                          periods.period_cal_end
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h4>
                    </v-col>

                    <v-col cols="12" md="12">
                      <v-file-input
                        v-model="documentsLinkfiles"
                        accept=".pdf"
                        name="documentsLinkfiles"
                        color="deep-purple accent-4"
                        counter
                        label="ไฟล์แบบเสนอย้ายและหลักฐานอื่นๆ .pdf"
                        placeholder="แบบคำร้องขอย้าย"
                        outlined
                        :show-size="1000"
                        :rules="rules"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-col>
                    <!--    <v-col cols="12" md="12">
                      <v-file-input
                        v-model="documentsLinkfiles2"
                        accept=".pdf"
                        name="documentsLinkfiles2"
                        color="deep-purple accent-4"
                        counter
                        label="ไฟล์ เอกสารชุดที่ 1 .pdf"
                        placeholder="เอกสารชุดที่ 1 ยกเว้นตัวชี้วัดที่ 1.4"
                        outlined
                        :show-size="1000"
                        :rules="rules"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-col>
 -->

                    <v-col cols="12" md="12">
                      <v-file-input
                        v-model="documentsLinkfiles3"
                        accept=".pdf"
                        name="documentsLinkfiles3"
                        color="deep-purple accent-4"
                        counter
                        label="ไฟล์ เอกสารตัวชี้วัดที่ 1.4 .pdf"
                        placeholder="เอกสารตัวชี้วัด 1.4"
                        outlined
                        :show-size="1000"
                        :rules="rules"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="updateFileDocdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="updateFileDocSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model comment_stb_Drdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="comment_stb_Drdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="เพิ่มความคิดเห็นผู้อำนวยการสถาบันการอาชีวศึกษา"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="updatecomment_stbform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ transference_personnels_id_ref.tid_ref }}
                        {{ transference_personnels_id_ref.id_tfp }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ transference_personnels_id_ref.id_card }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ transference_personnels_id_ref.title_s
                        }}{{ transference_personnels_id_ref.frist_name }}
                        {{ transference_personnels_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ transference_personnels_id_ref.college_code }}
                        {{ transference_personnels_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_personnels_id_ref.id_position }}
                      </h3>
                      <h4>
                        ย้ายครั้งที่ :
                        {{ transference_personnels_id_ref.time_ss }} ปี :
                        {{ this.transference_personnels_id_ref.year_ss }}
                      </h4>
                      <h4>
                        คำนวณอายุงาน ณ สถานศึกษาปัจจุบัน โดยใช้วันที่ :
                        {{
                          periods.period_cal_end
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h4>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-alert
                        class="mx-auto text-center pa-2 ma-2"
                        border="top"
                        colored-border
                        type="info"
                        elevation="2"
                      >
                        <h2>ความคิดเห็น</h2>
                        <h2>
                          <v-radio-group
                            v-model="
                              transference_personnels_id_ref.tp_comment_dr_stb
                            "
                            row
                            required
                            :rules="[v => !!v || '']"
                            align="center"
                          >
                            <v-radio value="approp">
                              <template v-slot:label>
                                <div>
                                  <strong class="primary--text"
                                    >เห็นควรให้ย้าย</strong
                                  >
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="inapprop">
                              <template v-slot:label>
                                <div>
                                  <strong class="warning--text"
                                    >ไม่เห็นควรให้ย้าย</strong
                                  >
                                </div>
                              </template>
                            </v-radio>
                            <v-radio value="suspend">
                              <template v-slot:label>
                                <div>
                                  <strong class="red--text">ระงับย้าย</strong>
                                </div>
                              </template>
                            </v-radio>
                          </v-radio-group>
                        </h2>
                      </v-alert>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-text-field
                        outlined
                        label=" ไม่เห็นควรให้ย้ายเนื่องจาก:"
                        v-model="
                          transference_personnels_id_ref.tp_reason_dr_stb
                        "
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="comment_stb_Drdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="updatecomment_stbSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model DeleteTpteachDrdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="DeleteTpteachDrdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="red"
              icon="mdi-clipboard-text"
              title="ลบรายการผู้ย้ายรายนี้"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="DeleteTpteachDrdialogform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ transference_personnels_id_ref.tid_ref }}
                        {{ transference_personnels_id_ref.id_tfp }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ transference_personnels_id_ref.id_card }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ transference_personnels_id_ref.title_s
                        }}{{ transference_personnels_id_ref.frist_name }}
                        {{ transference_personnels_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ transference_personnels_id_ref.college_code }}
                        {{ transference_personnels_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_personnels_id_ref.id_position }}
                      </h3>
                      <h4>
                        ย้ายครั้งที่ :
                        {{ transference_personnels_id_ref.time_ss }} ปี :
                        {{ this.transference_personnels_id_ref.year_ss }}
                      </h4>
                      <h4>
                        คำนวณอายุงาน ณ สถานศึกษาปัจจุบัน โดยใช้วันที่ :
                        {{
                          periods.period_cal_end
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h4>
                    </v-col>
                    <v-col>
                      <v-text-field
                        filled
                        rounded
                        type="password"
                        label="Password"
                        :rules="[v => v === '0612698358']"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="DeleteTpteachDrdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn large color="green" @click.stop="DeleteSubmit()" rounded>
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model CancelFilePDFDrdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="CancelFilePDFDrdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="ยกเลิกการแนบไฟล์"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="CancelFilePDFDrdialogform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ transference_personnels_id_ref.tid_ref }}
                        {{ transference_personnels_id_ref.id_tfp }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ transference_personnels_id_ref.id_card }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ transference_personnels_id_ref.title_s
                        }}{{ transference_personnels_id_ref.frist_name }}
                        {{ transference_personnels_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ transference_personnels_id_ref.college_code }}
                        {{ transference_personnels_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_personnels_id_ref.id_position }}
                      </h3>
                      <h4>
                        ย้ายครั้งที่ :
                        {{ transference_personnels_id_ref.time_ss }} ปี :
                        {{ this.transference_personnels_id_ref.year_ss }}
                      </h4>
                      <h4>
                        คำนวณอายุงาน ณ สถานศึกษาปัจจุบัน โดยใช้วันที่ :
                        {{
                          periods.period_cal_end
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h4>
                    </v-col>
                    <v-col>
                      <v-text-field
                        filled
                        rounded
                        type="password"
                        label="Password"
                        :rules="[v => v === '0612698358']"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="CancelFilePDFDrdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="CandelFilePDFSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยันยกเลิก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model CancelFilePDFProvinceDrdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="CancelFilePDFProvinceDrdialog"
          persistent
          max-width="60%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="ยกเลิกการแนบไฟล์ สำนักงานอาชีวศึกษาจังหวัด"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="CancelFilePDFProvinceDrdialogform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ transference_personnels_id_ref.tid_ref }}
                        {{ transference_personnels_id_ref.id_tfp }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ transference_personnels_id_ref.id_card }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ transference_personnels_id_ref.title_s
                        }}{{ transference_personnels_id_ref.frist_name }}
                        {{ transference_personnels_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ transference_personnels_id_ref.college_code }}
                        {{ transference_personnels_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_personnels_id_ref.id_position }}
                      </h3>
                      <h4>
                        ย้ายครั้งที่ :
                        {{ transference_personnels_id_ref.time_ss }} ปี :
                        {{ this.transference_personnels_id_ref.year_ss }}
                      </h4>
                      <h4>
                        คำนวณอายุงาน ณ สถานศึกษาปัจจุบัน โดยใช้วันที่ :
                        {{
                          periods.period_cal_end
                            | moment("add", "543 years")
                            | moment("D MMMM YYYY")
                        }}
                      </h4>
                    </v-col>
                    <v-col>
                      <v-text-field
                        filled
                        rounded
                        type="password"
                        label="Password"
                        :rules="[v => v === '0612698358']"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="CancelFilePDFProvinceDrdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="CancelFilePDFProvinceDrdialogSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยันยกเลิก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model canceltlMcommitteedialog -->
      <v-layout row justify-center>
        <v-dialog v-model="canceltlMcommitteedialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="ยกเลิกการความเห็นชอบปลายทาง"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="canceltlMcommitteedialogform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ transference_locationTfl.id_tfl }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ transference_locationTfl.id_card }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ transference_locationTfl.title_s
                        }}{{ transference_locationTfl.frist_name }}
                        {{ transference_locationTfl.last_name }}
                      </h3>
                      <h4>
                        ย้ายครั้งที่ :
                        {{ transference_locationTfl.time_ss }} ปี :
                        {{ this.transference_locationTfl.year_ss }}
                      </h4>
                      <h2>
                        ปลายทาง : {{ transference_locationTfl.college_name }}
                      </h2>
                      <h4>
                        {{ transference_locationTfl.tlMdocumentsLinkfile }}
                      </h4>
                    </v-col>
                    <v-col>
                      <v-text-field
                        filled
                        rounded
                        type="password"
                        label="Password"
                        :rules="[v => v === '0612698358']"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="canceltlMcommitteedialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="canceltlMcommitteeSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยันยกเลิก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdfdocumentsLinkfileDialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdfdocumentsLinkfileDialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HRvecfiles/' + pdf_files"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>{{ snackbar.text }}</v-card-text>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import Movementmenu from "../../components/admin/movementmenu.vue";

export default {
  data() {
    return {
      value: "2",
      loading: false,
      ApiKey: "HRvec2021",
      position: "ครู",
      branch_s: [],
      tid_ref: [],
      valid: true,
      canceldialog: false,
      positiondialog: false,
      positionMoveCutdialog: false,
      clear_dataDialog: false,
      commentDrdialog: false,
      updateFileDocdialog: false,
      comment_stb_Drdialog: false,
      DeleteTpteachDrdialog: false,
      CancelFilePDFDrdialog: false,
      CancelFilePDFProvinceDrdialog: false,
      canceltlMcommitteedialog: false,
      documentsLinkfiles: null,
      documentsLinkfiles2: null,
      documentsLinkfiles3: null,
      selectIdPositon: false,
      pdfdocumentsLinkfileDialog: false,
      times_select: "1",
      years_select: "2565",
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      time_ss: [1, 2],
      year_ss: [2565, 2566, 2567, 2568, 2569, 2570],
      transference_personnels: [],
      edittransference_personnel: {},
      search: "",
      pagination: {},
      singleSelect: false,
      selected: [],
      headers: [],
      selectedHeaders: [],
      headersMap: [
        { text: "ย้ายแบบที่ 1", align: "center", value: "actions" },
        { text: "ตัดโอน", align: "center", value: "MoveCut" },
        { text: "แห่งใหม่", align: "center", value: "succ_college" },
        { text: "วิทยาลัยแห่งใหม่", align: "left", value: "college_name_suss" },
        { text: "เลขที่ตำแหน่งใหม่", align: "left", value: "id_position_new" },
        { text: "รหัสบัตรประชาชน", align: "center", value: "id_card" },
        { text: "สถานะ", align: "center", value: "transfer_status" },
        { text: "จังหวัด", align: "center", value: "province_name" },
        { text: "ชื่อ-นามกสุล", align: "left", value: "personnel_name" },
        { text: "สถานศึกษาปัจจุบัน", align: "left", value: "college_name" },
        { text: "เลขที่ตำแหน่งเดิม", align: "left", value: "id_position" },
        { text: "ครั้งที่/ปี", align: "center", value: "time_ss" },

        { text: "อายุงาน ณ ปัจจุบัน", align: "center", value: "age_app_time" },
        { text: "อายุงานรวม", align: "center", value: "age_time" },
        { text: "วันที่บรรจุ", align: "center", value: "dateApps" },
        { text: "เงือนไข", align: "center", value: "type_personnel" },

        { text: "ความคิดเห็น ผอ.วิทฯ", align: "center", value: "comment_dr_c" },
        {
          text: "ความคิดเห็น ผอ.สถาฯ",
          align: "center",
          value: "tp_comment_dr_stb"
        },
        { text: "สถานะ", align: "center", value: "status_document" },
        { text: "แก้ไขแนบไฟล์", align: "center", value: "updateFileDoc" },
        { text: "ว.แบบคำขอ", align: "center", value: "documentsLinkfile" },
        /*    {
                     text: "ว.เอกสาร 1",
                     align: "center",
                     value: "doccon_1"
                   }, */
        /*   {
          text: "ว.เอกสาร 1.4",
          align: "center",
          value: "doccon_14"
        }, */
        {
          text: "สถานะรับเอกสาร",
          align: "center",
          value: "actionReceipt"
        },
        {
          text: "สอจ.แบบบันทึกผลรวมคะแนน",
          align: "center",
          value: "tpvecprovince_scoreFile"
        },
        { text: "สอจ.คะแนน", align: "center", value: "tpvecprovince_score" },
        { text: "กก.7", align: "center", value: "score_1420" },
        { text: "วันที่ทำรายการ", align: "center", value: "date_time" },

        { text: "Del", align: "left", value: "del" }
      ],
      header_trans: [
        { text: "ลำดับ", align: "center", value: "tlsequence_n" },
        { text: "รหัสอ้างอิง", align: "center", value: "tlid_ref" },
        { text: "สาขาวิชา", align: "center", value: "name_branch" },
        { text: "วิทยาลัย", align: "left", value: "tlcollege_code" },
        { text: "วิทยาลัย", align: "left", value: "college_name" },
        { text: "ความเห็น ปลายทาง", align: "left", value: "tlMcommittee" },
        { text: "เอกสารประชุม", align: "left", value: "tlMdocumentsLinkfile" },
        { text: "เลขที่ตำแหน่ง", align: "left", value: "idPosition" },
        { text: "ดำเนินการ", align: "left", value: "actions" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      transference_personnelstatus: [],
      transference_personnels_id_ref: [],
      conditons_transfer_successs: [],
      updatepositions_condition: {},
      man_power_cancel: {},
      addreturn_man_power: {},
      transference_locations: [],
      conditons_transfer_success_del: [],
      man_powers: [],
      userstatus: {},
      updatepositions: {},
      man_powerss: [],
      id_return_man_powers: [],
      colleges: [],
      data_select: [],

      periods: [],
      period_enable_process: "1",
      updatecomment: {},
      transference_locationTfl: [],
      updatetlMcommittee: {},
      transference_personnelCount: {},
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB",
        value => !!value || ""
      ],
      updateOptimize: {},
      pdf_files: []
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },
  async mounted() {
    await this.sweetAlertLoading();

    let result_branch;
    result_branch = await this.$http.post("branch.php", {
      ApiKey: this.ApiKey
    });
    this.branch_s = result_branch.data;
    let result;
    result = await this.$http.post("college.php", {
      ApiKey: this.ApiKey
    });
    this.colleges = result.data;
    await this.periodQuery();
    await this.transference_personnelQueryAll();
    Swal.close();
  },
  methods: {
    async clearRecordDontSave() {
      Swal.fire({
        title: "ต้องการลบรายการที่ไม่ได้บันทึก ?",
        text: "หลังจากปิดระบบเสนอย้าย",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ดำเนินการ",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          await this.sweetAlertLoading();
          let result = await this.$http.post(
            "transference_location.delete.admin.php"
          );
          if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการลบข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
            this.transference_personnelQueryAll();
          } else {
            Swal.fire({
              icon: "warning",
              title: "ดำเนินการลบผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
          Swal.close();
        }
      });
    },

    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    },

    async optimizeData() {
      Swal.fire({
        title: "ต้องการปรับปรุงข้อมูลเพื่อให้การประมวลผลที่เร็วขึ้น ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ดำเนินการ",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          await this.sweetAlertLoading();
          this.updateOptimize.ApiKey = this.ApiKey;
          this.updateOptimize.time_s = this.periods.period_times;
          this.updateOptimize.year_s = this.periods.period_year;
          let result = await this.$http.post(
            "transference_update_admin.php",
            this.updateOptimize
          );
          
          if (result.data.status == true) {
            Swal.fire({
              icon: "success",
              title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "ปรับปรุงข้อมูลเรียบร้อยแล้ว",
              showConfirmButton: false,
              timer: 1500
            });
          }
          Swal.close();
        }
      });
    },
    async viewdocumentsLinkfile(documentsLinkfile) {
      this.pdf_files = documentsLinkfile;
      this.pdfdocumentsLinkfileDialog = true;
    },

    async changeStatus(tid_ref) {
      let result_con = await this.$http.post("transference_personnel.php", {
        ApiKey: this.ApiKey,
        id_ref: tid_ref
      });
      this.transference_personnels_id_ref = result_con.data;
      let text =
        this.transference_personnels_id_ref.frist_name +
        this.transference_personnels_id_ref.last_name;

      Swal.fire({
        title: "คุณต้องการเปลี่ยนสถานะ?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "เปลี่ยนสถานะ",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.transference_personnels_id_ref.ApiKey = this.ApiKey;
          this.transference_personnels_id_ref.transfer_status = "save";
          let resultupdate = await this.$http.post(
            "transference_personnel.update.php",
            this.transference_personnels_id_ref
          );
          await this.transference_personnelQueryAll();
        }
      });
    },
    async Searchtemporary() {
      this.loading = true;
      let result = await this.$http
        .post("transference_personnel.php", {
          ApiKey: this.ApiKey,
          time_s: this.periods.period_times,
          year_s: this.periods.period_year,
          transfer_status: "temporary"
        })
        .finally(() => (this.loading = false));
      this.transference_personnels = result.data;
    },

    async SearchCCpvcdoc() {
      let result = await this.$http
        .post("transference_personnel.php", {
          ApiKey: this.ApiKey,
          time_s: this.periods.period_times,
          year_s: this.periods.period_year,
          type_move: "nm",
          ccDocumentFilePVC: "cc"
        })
        .finally(() => (this.loading = false));
      this.transference_personnels = result.data;
    },

    async SearchCCdoc() {
      let result = await this.$http
        .post("transference_personnel.php", {
          ApiKey: this.ApiKey,
          time_s: this.periods.period_times,
          year_s: this.periods.period_year,
          type_move: "nm",
          ccDocumentFile: "cc"
        })
        .finally(() => (this.loading = false));
      this.transference_personnels = result.data;
    },

    async actionReceipts(id_tfp) {
      this.updatetlMcommittee.ApiKey = this.ApiKey;
      this.updatetlMcommittee.id_tfp = id_tfp;
      this.updatetlMcommittee.tp14receipt = "success";
      let result = await this.$http.post(
        "transference_personnel.update.receipt.php",
        this.updatetlMcommittee
      );
      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.transference_personnelQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    async actionReceiptUdate(id_tfp) {
      this.updatetlMcommittee.ApiKey = this.ApiKey;
      this.updatetlMcommittee.id_tfp = id_tfp;
      this.updatetlMcommittee.tp14receipt = "";
      let result = await this.$http.post(
        "transference_personnel.update.receipt.php",
        this.updatetlMcommittee
      );
      if (result.data.status == true) {
        Swal.fire({
          icon: "success",
          title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
          showConfirmButton: false,
          timer: 1500
        });
        this.transference_personnelQueryAll();
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
    },
    row_classes(item) {
      if (item.id_position_new > 0) {
        return "info";
      } else if (item.ccDocumentFile == "cc") {
        return "yellow";
      } else if (item.ccDocumentFilePVC == "cc") {
        return "yellow";
      }

      if (item.type_personnel == "select") {
        return "orange";
      }
    },
    async selectIdpostion(id_tfl, tlcollege_code) {
      let result = await this.$http.post("transference_location.php", {
        ApiKey: this.ApiKey,
        id_tfl: id_tfl
      });
      this.transference_locationTfl = result.data;
      let man_power_result;
      man_power_result = await this.$http.post("man_power_process.php", {
        ApiKey: this.ApiKey,
        college_code: tlcollege_code
      });
      this.man_powers = man_power_result.data;
      this.selectIdPositon = true;
    },
    async canceltlMcommittee(id_tfl) {
      let result = await this.$http.post("transference_location.php", {
        ApiKey: this.ApiKey,
        id_tfl: id_tfl
      });
      this.transference_locationTfl = result.data;
      this.canceltlMcommitteedialog = true;
    },
    async canceltlMcommitteeSubmit() {
      if (this.$refs.canceltlMcommitteedialogform.validate()) {
        this.updatetlMcommittee.ApiKey = this.ApiKey;
        this.updatetlMcommittee.id_tfl = this.transference_locationTfl.id_tfl;
        this.updatetlMcommittee.tlMcommittee = "";
        this.updatetlMcommittee.tlMdocumentsLinkfile = "";
        let result;
        result = await this.$http.post("deletefile.php", {
          ApiKey: this.ApiKey,
          filename:
            "../HRvecfiles/" +
            this.transference_locationTfl.tlMdocumentsLinkfile
        });
        result = await this.$http.post(
          "transference_location.update.movein.php",
          this.updatetlMcommittee
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินยกเลิกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_personnelQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.canceltlMcommitteedialog = false;
        this.positiondialog = false;
      }
    },
    async CancelFilePDFProvince(tid_ref) {
      let result_con = await this.$http.post("transference_personnel.php", {
        ApiKey: this.ApiKey,
        id_ref: tid_ref
      });
      this.transference_personnels_id_ref = result_con.data;
      this.CancelFilePDFProvinceDrdialog = true;
    },
    async CancelFilePDF(tid_ref) {
      let result_con = await this.$http.post("transference_personnel.php", {
        ApiKey: this.ApiKey,
        id_ref: tid_ref
      });
      this.transference_personnels_id_ref = result_con.data;
      this.CancelFilePDFDrdialog = true;
    },
    async CancelFilePDFProvinceDrdialogSubmit() {
      if (this.$refs.CancelFilePDFProvinceDrdialogform.validate()) {
        let result;
        this.transference_personnels_id_ref.ApiKey = this.ApiKey;
        result = await this.$http.post("deletefile.php", {
          ApiKey: this.ApiKey,
          filename:
            "../HRvecfiles/" +
            this.transference_personnels_id_ref.tpvecprovince_scoreFile
        });
        result = await this.$http.post(
          "transference_personnel.update.vp.php",
          this.transference_personnels_id_ref
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_personnelQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.CancelFilePDFProvinceDrdialog = false;
      }
    },
    async CandelFilePDFSubmit() {
      if (this.$refs.CancelFilePDFDrdialogform.validate()) {
        let result;
        this.transference_personnels_id_ref.ApiKey = this.ApiKey;
        result = await this.$http.post("deletefile.php", {
          ApiKey: this.ApiKey,
          filename:
            "../HRvecfiles/" +
            this.transference_personnels_id_ref.documentsLinkfile
        });
        result = await this.$http.post("deletefile.php", {
          ApiKey: this.ApiKey,
          filename:
            "../HRvecfiles/" + this.transference_personnels_id_ref.doccon_14
        });
        result = await this.$http.post(
          "transference_personnel.update.admin.php",
          this.transference_personnels_id_ref
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_personnelQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.CancelFilePDFDrdialog = false;
      }
    },
    async deletetpteach(tid_ref) {
      let result_con = await this.$http.post("transference_personnel.php", {
        ApiKey: this.ApiKey,
        id_ref: tid_ref
      });
      this.transference_personnels_id_ref = result_con.data;
      this.DeleteTpteachDrdialog = true;
    },
    async DeleteSubmit() {
      if (this.$refs.DeleteTpteachDrdialogform.validate()) {
        this.transference_personnels_id_ref.ApiKey = this.ApiKey;
        let result = await this.$http.post(
          "transference_personnel.delete.php",
          this.transference_personnels_id_ref
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_personnelQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.DeleteTpteachDrdialog = false;
      }
    },
    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_enable_process: "1",
        period_id: "301"
      });
      this.periods = result_period.data;
    },
    async searchTimeYear() {
      this.loading = true;
      let result = await this.$http
        .post("transference_personnel.php", {
          ApiKey: this.ApiKey,
          time_s: this.times_select,
          year_s: this.years_select,
          type_move: "nm"
        })
        .finally(() => (this.loading = false));
      this.transference_personnels = result.data;
    },
    async showSuccessPosition() {
      this.loading = true;
      let result = await this.$http
        .post("transference_personnel.php", {
          ApiKey: this.ApiKey,
          time_s: this.times_select,
          year_s: this.years_select,
          type_move: "nm",
          success_s: "ok"
        })
        .finally(() => (this.loading = false));
      this.transference_personnels = result.data;
    },
    async select_idPosition(tid_ref) {
      let result_con = await this.$http.post("transference_personnel.php", {
        ApiKey: this.ApiKey,
        id_ref: tid_ref
      });
      let result = await this.$http.post("transference_location.php", {
        ApiKey: this.ApiKey,
        id_ref: tid_ref
      });
      this.updatepositions = {};
      this.transference_locations = result.data;
      this.transference_personnels_id_ref = result_con.data;
      this.positiondialog = true;
    },
    async select_idPositionMoveCut(tid_ref) {
      let result_con = await this.$http.post("transference_personnel.php", {
        ApiKey: this.ApiKey,
        id_ref: tid_ref
      });
      let result = await this.$http.post("transference_location.php", {
        ApiKey: this.ApiKey,
        id_ref: tid_ref
      });
      this.updatepositions = {};
      this.transference_locations = result.data;
      this.transference_personnels_id_ref = result_con.data;
      this.positionMoveCutdialog = true;
    },
    async comment_idPosition(tid_ref) {
      let result_con = await this.$http.post("transference_personnel.php", {
        ApiKey: this.ApiKey,
        id_ref: tid_ref
      });
      this.transference_personnels_id_ref = result_con.data;
      this.commentDrdialog = true;
    },

    async updateFileDoc(tid_ref) {
      let result_con = await this.$http.post("transference_personnel.php", {
        ApiKey: this.ApiKey,
        id_ref: tid_ref
      });
      this.transference_personnels_id_ref = result_con.data;
      this.updateFileDocdialog = true;
      this.documentsLinkfiles = null;
      this.documentsLinkfiles3 = null;
    },

    async tp_comment_dr_stbPosition(tid_ref) {
      let result_con = await this.$http.post("transference_personnel.php", {
        ApiKey: this.ApiKey,
        id_ref: tid_ref
      });
      this.transference_personnels_id_ref = result_con.data;
      this.comment_stb_Drdialog = true;
    },
    async transference_personnelQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("transference_personnel.php", {
          ApiKey: this.ApiKey,
          time_s: this.periods.period_times,
          year_s: this.periods.period_year,
          type_move: "nm"
        })
        .finally(() => (this.loading = false));
      this.transference_personnels = result.data;
      let data = this.transference_personnels;
      let sum = 0;
      let sumcc = 0;
      let sumpvccc = 0;
      let sumsuc = 0;
      let sumdoc = 0;
      let sumdoc14 = 0;
      let sumdocpvcfile = 0;
      let conditions = 0;
      let localDevs = 0;
      let sounths = 0;
      data.forEach(value => {
        sum = sum + 1;
        if (value.ccDocumentFile === "cc") {
          sumcc = sumcc + 1;
        }
        if (value.ccDocumentFilePVC === "cc") {
          sumpvccc = sumpvccc + 1;
        }

        if (value.transfer_status == "save") {
          sumsuc = sumsuc + 1;
        }
        let dataFile = String(value.documentsLinkfile);
        if (dataFile.length > 6) {
          sumdoc = sumdoc + 1;
        }
        if (value.doccon_14) {
          sumdoc14 = sumdoc14 + 1;
        }
        if (value.tpvecprovince_scoreFile) {
          sumdocpvcfile = sumdocpvcfile + 1;
        }

        if (value.type_personnel == "select") {
          conditions = conditions + 1;
        }
        if (value.type_personnel == "local_dev") {
          localDevs = localDevs + 1;
        }

        if (
          value.province_difficulty == "1" ||
          value.prefecture_difficulty == "1"
        ) {
          sounths = sounths + 1;
        }
      });
      this.transference_personnelCount.sumAll = sum;
      this.transference_personnelCount.sumcc = sumcc;
      this.transference_personnelCount.sumpvccc = sumpvccc;
      this.transference_personnelCount.sumsuc = sumsuc;
      this.transference_personnelCount.sumdoc = sumdoc;
      this.transference_personnelCount.sumdoc14 = sumdoc14;
      this.transference_personnelCount.sumdocpvcfile = sumdocpvcfile;
      this.transference_personnelCount.conditions = conditions;
      this.transference_personnelCount.localDevs = localDevs;
      this.transference_personnelCount.sounths = sounths;
    },
    async man_powerQuery() {
      let man_power_result;
      man_power_result = await this.$http.post("man_power_process.php", {
        ApiKey: this.ApiKey,
        college_code: this.updatepositions.ctscollege_code
      });
      this.man_powers = man_power_result.data;
    },
    async updatecommentSubmit() {
      if (this.$refs.updatecommentform.validate()) {
        this.updatecomment.ApiKey = this.ApiKey;
        this.updatecomment.id_tfp = this.transference_personnels_id_ref.id_tfp;
        this.updatecomment.comment_dr_c = this.transference_personnels_id_ref.comment_dr_c;
        this.updatecomment.id_branch = this.transference_personnels_id_ref.id_branch_tan;
        this.updatecomment.detail_comment = this.transference_personnels_id_ref.detail_comment;
        this.updatecomment.status_document = this.transference_personnels_id_ref.status_document;

        let result = await this.$http.post(
          "transference_personnel.update.php",
          this.updatecomment
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_personnelQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.commentDrdialog = false;
      }
    },

    async updateFileDocSubmit() {
      if (this.$refs.updateFileDocform.validate()) {
        this.updatecomment.ApiKey = this.ApiKey;
        this.updatecomment.id_tfp = this.transference_personnels_id_ref.id_tfp;
        let result = "";
        let uploaded = null;

        if (this.documentsLinkfiles != "") {
          let formData = new FormData();
          let filename =
            this.transference_personnels_id_ref.tid_ref +
            "" +
            this.time_stamp +
            "" +
            "doc.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.documentsLinkfiles);
          formData.append("filename", "../HRvecfiles/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          if (result.data.status == true) {
            this.updatecomment.documentsLinkfile = filename;
          } else {
            
          }
          uploaded = true;
        } else {
          uploaded = false;
        }

        if (this.documentsLinkfiles3 != "") {
          let formData = new FormData();
          let filename =
            this.transference_personnels_id_ref.tid_ref +
            "" +
            this.time_stamp +
            "" +
            "cp14.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.documentsLinkfiles3);
          formData.append("filename", "../HRvecfiles/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });

          if (result.data.status == true) {
            this.updatecomment.doccon_14 = filename;
          } else {
            
          }
          uploaded = true;
        } else {
          uploaded = false;
        }

        result = await this.$http.post(
          "transference_personnel.update.php",
          this.updatecomment
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_personnelQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.updateFileDocdialog = false;
      }
    },

    async updatecomment_stbSubmit() {
      if (this.$refs.updatecomment_stbform.validate()) {
        this.updatecomment.ApiKey = this.ApiKey;
        this.updatecomment.id_tfp = this.transference_personnels_id_ref.id_tfp;
        this.updatecomment.tp_comment_dr_stb = this.transference_personnels_id_ref.tp_comment_dr_stb;
        this.updatecomment.tp_reason_dr_stb = this.transference_personnels_id_ref.tp_reason_dr_stb;
        let result = await this.$http.post(
          "transference_personnel.update.php",
          this.updatecomment
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_personnelQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.comment_stb_Drdialog = false;
      }
    },
    async updateScore1420(id_tfp, score_1420) {
      this.updatecomment.ApiKey = this.ApiKey;
      this.updatecomment.id_tfp = id_tfp;
      this.updatecomment.score_1420 = score_1420;
      if (score_1420 > 40) {
        Swal.fire({
          icon: "warning",
          title: "คะแนนไม่ถูกต้อง",
          showConfirmButton: false,
          timer: 1500
        });
        this.transference_personnelQueryAll();
      } else if (score_1420 < 0) {
        Swal.fire({
          icon: "warning",
          title: "คะแนนไม่ถูกต้อง",
          showConfirmButton: false,
          timer: 1500
        });
        this.transference_personnelQueryAll();
      } else {
        let result = await this.$http.post(
          "transference_personnel.update.php",
          this.updatecomment
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
      }
    },
    /// updatepositionSubmit
    async updatepositionSubmit() {
      if (this.$refs.updatepositionform.validate()) {
        this.updatepositions.ApiKey = this.ApiKey;
        this.updatepositions.ctsid_ref = this.transference_personnels_id_ref.tid_ref;
        this.updatepositions.ctstime_s = this.transference_personnels_id_ref.time_ss;
        this.updatepositions.ctsyear_s = this.transference_personnels_id_ref.year_ss;
        this.updatepositions.ctsid_postion_old = this.transference_personnels_id_ref.id_position;
        this.updatepositions.ctscollege_code_old = this.transference_personnels_id_ref.college_code;
        /*         this.updatepositions.ctscollege_code = this.transference_locationTfl.college_code;
        this.updatepositions.ctsid_branch = this.transference_locationTfl.tlid_branch; */
        this.updatepositions.ctsid_card = this.transference_personnels_id_ref.id_card;
        this.updatepositions.ctsname_position = this.position;
        this.updatepositions.ctsstatus_select = "demand";
        this.updatepositions_condition.ApiKey = this.ApiKey;
        this.updatepositions_condition.id_position = this.updatepositions.ctsid_position;
        this.updatepositions_condition.status_booking = this.transference_personnels_id_ref.id_card;
        this.addreturn_man_power.ApiKey = this.ApiKey;
        this.addreturn_man_power.college_code = this.transference_personnels_id_ref.college_code;
        this.addreturn_man_power.id_position = this.transference_personnels_id_ref.id_position;
        this.addreturn_man_power.position = this.position;
        this.addreturn_man_power.case_vacancy =
          "ย้ายรอบ-" +
          this.transference_personnels_id_ref.time_ss +
          "/" +
          this.transference_personnels_id_ref.year_ss;
        let result_man_return = await this.$http.post(
          "man_power.insert.php",
          this.addreturn_man_power
        );
        if (result_man_return.data.status == true) {
          let result_man = await this.$http.post(
            "man_power.update_process.php",
            this.updatepositions_condition
          );
          let result = await this.$http.post(
            "conditons_transfer_success.insert.php",
            this.updatepositions
          );
          if (result_man.data.status == true && result.data.status == true) {
            this.snackbar.icon = "mdi-font-awesome";
            this.snackbar.color = "success";
            this.snackbar.text = "บันทึกข้อมูลเรียบร้อย";
            this.snackbar.show = true;
            this.transference_personnelQueryAll();
          }
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.positiondialog = false;
        this.selectIdPositon = false;
      }
    },
    /// updatepositionMoveCutSubmit
    async updatepositionMoveCutSubmit() {
      if (this.$refs.updatepositionMoveCutform.validate()) {
        this.updatepositions.ApiKey = this.ApiKey;
        this.updatepositions.ctstime_s = this.transference_personnels_id_ref.time_ss;
        this.updatepositions.ctsyear_s = this.transference_personnels_id_ref.year_ss;
        this.updatepositions.ctscollege_code_old = this.transference_personnels_id_ref.college_code;
        this.updatepositions.ctsid_postion_old = this.transference_personnels_id_ref.id_position;
        this.updatepositions.ctsid_position = this.transference_personnels_id_ref.id_position;
        this.updatepositions.ctsid_card = this.transference_personnels_id_ref.id_card;
        this.updatepositions.ctsid_ref = this.transference_personnels_id_ref.tid_ref;
        this.updatepositions.ctsname_position = this.position;
        this.updatepositions.ctsstatus_select = "transfer";
        let result = await this.$http.post(
          "conditons_transfer_success.insert.php",
          this.updatepositions
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "บันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.transference_personnelQueryAll();
        }
      } else {
        Swal.fire({
          icon: "warning",
          title: "ดำเนินการผิดพลาด",
          showConfirmButton: false,
          timer: 1500
        });
      }
      this.positionMoveCutdialog = false;
    },
    async deletePosition(id_ref) {
      let result_con = await this.$http.post("transference_personnel.php", {
        ApiKey: this.ApiKey,
        id_ref: id_ref
      });
      this.transference_personnels_id_ref = result_con.data;
      let result_man = await this.$http.post("man_power.php", {
        ApiKey: this.ApiKey,
        id_card: this.transference_personnels_id_ref.id_card
      });
      this.man_powerss = result_man.data;
      let result_cts = await this.$http.post("conditons_transfer_success.php", {
        ApiKey: this.ApiKey,
        ctsid_ref: id_ref
      });
      this.conditons_transfer_successs = result_cts.data;
      this.canceldialog = true;
    },
    async cancelSubmit() {
      if (this.$refs.cancelform.validate()) {
        this.man_powerss.ApiKey = this.ApiKey;
        this.man_powerss.status_booking = "";
        this.conditons_transfer_successs.ApiKey = this.ApiKey;
        this.man_power_cancel.ApiKey = this.ApiKey;
        this.man_power_cancel.id_position = this.conditons_transfer_successs.ctsid_postion_old;
        let result_man = await this.$http.post(
          "man_power.update_process.php",
          this.man_powerss
        );
        let result_man_delete = await this.$http.post(
          "man_power.delete.php",
          this.man_power_cancel
        );
        let result_cts = await this.$http.post(
          "conditons_transfer_success.delete.php",
          this.conditons_transfer_successs
        );
        if (
          result_man.data.status == true &&
          result_cts.data.status == true &&
          result_man_delete.data.status == true
        ) {
          this.snackbar.icon = "mdi-font-awesome";
          this.snackbar.color = "success";
          this.snackbar.text = "ยกเลิกข้อมูลเรียบร้อย";
          this.snackbar.show = true;
          this.transference_personnelQueryAll();
        } else {
          this.snackbar.icon = "mdi-close-network";
          this.snackbar.color = "red";
          this.snackbar.text = "ยกเลิกข้อมูลผิดพลาด";
          this.snackbar.show = true;
        }
        this.canceldialog = false;
      }
    },
    getColor(calories) {
      if (calories > 0) return "green";
      else return "";
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    time_stamp() {
      let time = Date.now();
      return time;
    },
    period_years() {
      let yyyy = this.periods.period_year;
      return yyyy;
    },
    color() {
      return "lime darken-4";
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  },
  components: { Movementmenu }
};
</script>

<style scoped>
.orange {
  background-color: rgb(236, 194, 115);
}
</style>
